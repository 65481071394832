import React from 'react';
import PropTypes from 'prop-types';
import { DiscountWrapper } from './Discount.styled';
const Discount = ({ discountData={
    imageUrl: "/img/discount.jpg",
    title: "Discount",
    season: "New Customers",
    saleText: "Sale",
    salePercentage: 20,
    countdown: [
      { value: "1", label: "Days" },
      { value: "18", label: "Hours" },
      { value: "46", label: "Minutes" },
      { value: "05", label: "Seconds" },
    ],
    linkUrl: "/shop",
}}) => (
  <DiscountWrapper data-testid="Discount">
    <section className="discount">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 p-0">
            <div className="discount__pic">
              <img src={discountData.imageUrl} alt="Discount" />
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="discount__text">
              <div className="discount__text__title">
                <span>{discountData.title}</span>
                <h2>{discountData.season}</h2>
                <h5>
                  <span>{discountData.saleText}</span> {discountData.salePercentage}%
                </h5>
              </div>
              <div className="discount__countdown" id="countdown-time">
                {discountData.countdown.map((item, index) => (
                  <div className="countdown__item" key={index}>
                    <span>{item.value}</span>
                    <p>{item.label}</p>
                  </div>
                ))}
              </div>
              <a href={discountData.linkUrl}>Shop now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </DiscountWrapper>
);

Discount.propTypes = {
  discountData: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    season: PropTypes.string.isRequired,
    saleText: PropTypes.string.isRequired,
    salePercentage: PropTypes.number.isRequired,
    countdown: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    linkUrl: PropTypes.string.isRequired,
  }),
};


export default Discount;
