import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ProductDetailsPicWrapper } from './ProductDetailsPic.styled';

const ProductDetailsPic = ({ images = [] }) => {
  // Initialize the image source with the first product image or placeholder
  const [imgSrc, setImgSrc] = useState(images && images.length > 0 ? images[0]["Image URL"] : '/img/product/placeholder.jpg');

  // Placeholder for image errors
  const placeholder = '/img/product/placeholder.jpg'; // Use your actual placeholder image path here

  // Handle error (image not loading)
  const handleError = () => {
    setImgSrc(placeholder); // Set the placeholder image if the product image fails to load
  };

  // Handle image click to update the full image
  const handleThumbnailClick = (fullImage) => {
    setImgSrc(fullImage);
  };

  return (
    <ProductDetailsPicWrapper data-testid="ProductDetailsPic">
      <div className="product__details__pic">
        <div className="product__details__pic__left product__thumb nice-scroll">
          {images && images.length > 0 ? images.map((image, index) => (
            <a
              key={index}
              className={`pt ${imgSrc === image["Image URL"] ? 'active' : ''}`}
              href={`#product-${index + 1}`}
              onClick={() => handleThumbnailClick(image["Image URL"])}
            >
              <img src={image["Image URL"]} alt={image.title} />
            </a>
          )) : <span>No images available</span>}
        </div>
        <div className="product__details__slider__content">
          <div className="product__details__pic__slider owl-carousel1">
            <img
              className="product__big__img"
              src={imgSrc}
              alt="Product"
              onError={handleError}
            />
          </div>
        </div>
      </div>
    </ProductDetailsPicWrapper>
  );
};

// Prop validation
ProductDetailsPic.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      "ASIN": PropTypes.string.isRequired,
      "Image variant": PropTypes.string.isRequired,
      "Image URL": PropTypes.string.isRequired,
      "title": PropTypes.string.isRequired,
    })
  ).isRequired,
};


export default ProductDetailsPic;
