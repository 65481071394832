import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbWrapper } from './Breadcrumb.styled';

const Breadcrumb = ({ breadcrumbs= [{ label: "Home", url: "./", icon: "fa fa-home" },{ label: "Shopping cart" }]}) => (
  <BreadcrumbWrapper data-testid="Breadcrumb">
    <div className="breadcrumb-option">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb__links">
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                  {breadcrumb.url ? (
                    <a href={breadcrumb.url}>
                      {breadcrumb.icon && <i className={breadcrumb.icon}></i>} {breadcrumb.label}
                    </a>
                  ) : (
                    <span>{breadcrumb.label}</span>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </BreadcrumbWrapper>
);

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};

export default Breadcrumb;
