import React from 'react';
import PropTypes from 'prop-types';
import { FooterWrapper } from './Footer.styled';

const Footer = ({ footerData }) => (
  <FooterWrapper data-testid="Footer">
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* Footer About Section */}
          <div className="col-lg-4 col-md-6 col-sm-7">
            <div className="footer__about">
              <div className="footer__logo">
                <a href={footerData.about.logoLink}>
                  <img src={footerData.about.logoSrc} alt="Logo" />
                </a>
              </div>
              <p>{footerData.about.description}</p>
              <div className="footer__payment">
                {footerData.about.paymentMethods.map((method, index) => (
                  <a href={method.link} key={index}>
                    <img src={method.imgSrc} alt={method.alt} />
                  </a>
                ))}
              </div>
            </div>
          </div>
          {/* Quick Links Section */}
          <div className="col-lg-2 col-md-3 col-sm-5">
            <div className="footer__widget">
              <h6>{footerData.quickLinks.title}</h6>
              <ul>
                {footerData.quickLinks.links.map((link, index) => (
                  <li key={index}>
                    <a href={link.href}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* Account Section */}
          <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="footer__widget">
              <h6>{footerData.account.title}</h6>
              <ul>
                {footerData.account.links.map((link, index) => (
                  <li key={index}>
                    <a href={link.href}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* Newsletter Section */}
          <div className="col-lg-4 col-md-8 col-sm-8">
            <div className="footer__newslatter">
              <h6>{footerData.newsletter.title}</h6>
              <form action={footerData.newsletter.formAction}>
                <input type="text" placeholder={footerData.newsletter.placeholder} />
                <button type="submit" className="site-btn">
                  {footerData.newsletter.buttonText}
                </button>
              </form>
              <div className="footer__social">
                {footerData.newsletter.socialLinks.map((social, index) => (
                  <a href={social.href} key={index}>
                    <i className={`fa ${social.iconClass}`}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Footer Copyright */}
        <div className="row">
          <div className="col-lg-12">
            <div className="footer__copyright__text">
              <p>
                {footerData.copyright.text}{' '}
                <a href={footerData.copyright.link.href} target="_blank" rel="noopener noreferrer">
                  {footerData.copyright.link.text}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </FooterWrapper>
);

Footer.propTypes = {
  footerData: PropTypes.shape({
    about: PropTypes.shape({
      logoLink: PropTypes.string.isRequired,
      logoSrc: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string.isRequired,
          imgSrc: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    quickLinks: PropTypes.shape({
      title: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    account: PropTypes.shape({
      title: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    newsletter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      formAction: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          iconClass: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    copyright: PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Footer;
