import React from 'react';
import { BannerWrapper } from './Banner.styled';

const bannerData = [
  {
    id: 1,
    subtitle: "The Chloe Collection",
    title: "The Project Jacket",
    linkText: "Shop now",
    linkUrl: "#",
  },
  {
    id: 2,
    subtitle: "The Chloe Collection",
    title: "The Project Jacket",
    linkText: "Shop now",
    linkUrl: "#",
  },
  {
    id: 3,
    subtitle: "The Chloe Collection",
    title: "The Project Jacket",
    linkText: "Shop now",
    linkUrl: "#",
  },
];


const Banner = () => (
  <BannerWrapper data-testid="Banner">
    <section
      className="banner set-bg"
      style={{ backgroundImage: `url('/img/banner/banner-1.jpg')` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-8 m-auto">
            <div className="banner__slider">
                

            <div className="banner__text">
                <span>The yobup Collection</span>
                <h1>Perfect for Every Occasion</h1>
                <a href="/shop" className="shop-btn">Shop now</a>
            </div>
            
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </BannerWrapper>
);

export default Banner;
