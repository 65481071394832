import React, { useState, useEffect } from "react";
import { WishlistPageWrapper } from "./WishlistPage.styled";
import {
  getWishlist,
  removeFromWishlist,
} from "../../utils/cookieUtils"; // Utility functions for cookie-based wishlist
import { addToCart } from "../../utils/cookieUtils";


const WishlistPage = () => {
    

const [wishlist, setWishlist] = useState([]);

useEffect(() => {
  // Load wishlist from cookies
  const wishlistData = getWishlist();
  setWishlist(wishlistData);
}, []);

const handleRemove = (itemId) => {
  removeFromWishlist(itemId);
  const updatedWishlist = getWishlist();
  setWishlist(updatedWishlist);
};

const handleAddToCart = (product) => {
   addToCart(product);
   
   removeFromWishlist(product.sku);
   const updatedWishlist = getWishlist();
   setWishlist(updatedWishlist);

   alert(`${product.product_name} added to cart!`);
   
};


return (
  <WishlistPageWrapper data-testid="WishlistPage">
    <section className="shop-wishlist shop-cart spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop__wishlist__table shop__cart__table">

            {wishlist.length > 0 ? 
                <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {wishlist.map((item) => (
                    <tr key={item.id}>
                      <td className="cart__product__item">
                        <img  style={{ maxWidth: '90px' }}  src={ item.images && item.images.length > 0  ? item.images[0]["Image URL"]  : '/img/product/placeholder.jpg' } alt={item.product_name} />

                        <div className="cart__product__item__title">
                          <h6>{item.product_name}</h6>
                          <div className="rating">
                            {[...Array(5)].map((_, i) => (
                              <i
                                key={i}
                                className={`fa fa-star ${
                                  i < item.rating ? "active" : ""
                                }`}
                              ></i>
                            ))}
                          </div>
                        </div>
                      </td>
                      <td className="cart__price">
                          ${item && item.price ? item.price.toFixed(2) : "0.00"}
                      </td>

                      <td className="cart__close">
                        <div className="wishlist__btn cart__btn">
                        <a href="#"  onClick={() => handleAddToCart(item)}>Add to Cart</a>&nbsp;
                        <a href="#"  onClick={() => handleRemove(item.sku)} >Remove</a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              : <div className="cart__btn">
                  <a href="/shop">Continue Shopping</a>
                </div>}


            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="wishlist__btn cart__btn">
              <a href="/shop">Continue Shopping</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </WishlistPageWrapper>
)};

export default WishlistPage;


