import React from 'react';
import PropTypes from 'prop-types';
import { SpecificationWrapper } from './Specification.styled';

const Specification = () => (
 <SpecificationWrapper data-testid="Specification">
    
    <h6>Specification</h6>
    <p></p>

 </SpecificationWrapper>
);


export default Specification;
