import React from 'react';
import PropTypes from 'prop-types';
import { InstagramWrapper } from './Instagram.styled';

const Instagram = ({ items = [], username = 'theyobup' }) => (
  <InstagramWrapper data-testid="Instagram">
    <div className="instagram">
      <div className="container-fluid">
        <div className="row">
          {items.map((item, index) => (
            <div className="col-lg-2 col-md-4 col-sm-4 p-0" key={index}>
              <div
                className="instagram__item set-bg"
                style={{ backgroundImage: `url(${item.imgSrc})` }}
              >
                <div className="instagram__text">
                  <i className="fa fa-instagram"></i>
                  <a href={`https://instagram.com/${username}`} target="_blank" rel="noopener noreferrer">
                    @{username}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </InstagramWrapper>
);

Instagram.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string.isRequired,
    })
  ).isRequired,
  username: PropTypes.string.isRequired,
};

export default Instagram;
