import React from 'react';
import PropTypes from 'prop-types';
import { OrderPageWrapper } from './OrderPage.styled';

const OrderPage = () => (
 <OrderPageWrapper data-testid="OrderPage">
      <section className="order-page spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>Order Information</h3>
              <p>
                We are not currently accepting orders directly through our website. However, you can find our products available for purchase on Amazon.
                Please feel free to check there for the items you're interested in.
              </p>
              <p>
                We appreciate your support and encourage you to keep an eye on our website for updates in the future. Thank you for your understanding!
              </p>
            </div>
          </div>
        </div>
      </section>

 </OrderPageWrapper>
);

OrderPage.propTypes = {};

OrderPage.defaultProps = {};

export default OrderPage;
