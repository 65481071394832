import React, { useState, useEffect } from "react";
import { CartPageWrapper } from "./CartPage.styled";
import {
  getCart,
  removeFromCart,
  updateCartItemQuantity,
} from "../../utils/cookieUtils"; // Utility functions for cookie-based cart

  
const CartPage = () => {
  const [cart, setCart] = useState([]);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    // Load cart from cookies and calculate subtotal
    const cartData = getCart();
    setCart(cartData);
    calculateSubtotal(cartData);
  }, []);

  const calculateSubtotal = (cartItems) => {
    const total = cartItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setSubtotal(total);
  };

  const handleRemove = (itemId) => {
    removeFromCart(itemId);
    const updatedCart = getCart();
    setCart(updatedCart);
    calculateSubtotal(updatedCart);
  };

  const handleQuantityChange = (itemId, quantity) => {
    updateCartItemQuantity(itemId, quantity);
    const updatedCart = getCart();
    setCart(updatedCart);
    calculateSubtotal(updatedCart);
  };

  
  console.log(cart , "cart")
  return (
    <CartPageWrapper data-testid="CartPage">
      <section className="shop-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shop__cart__table">

                {cart.length > 0 ? 
                <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item) => (
                    <tr key={item.id}>
                      <td className="cart__product__item">
                        <img  style={{ maxWidth: '90px' }}  src={ item.images && item.images.length > 0  ? item.images[0]["Image URL"]  : '/img/product/placeholder.jpg' } alt={item.product_name} />

                        <div className="cart__product__item__title">
                          <h6>{item.product_name}</h6>
                          <div className="rating">
                            {[...Array(5)].map((_, i) => (
                              <i
                                key={i}
                                className={`fa fa-star ${
                                  i < item.rating ? "active" : ""
                                }`}
                              ></i>
                            ))}
                          </div>
                        </div>
                      </td>
                      <td className="cart__price">
                          ${item && item.price ? item.price.toFixed(2) : "0.00"}
                      </td>
                      <td className="cart__quantity">
                        <div className="pro-qty">
                          <input
                            type="number"
                            value={item.quantity}
                            min="1"
                            onChange={(e) =>
                              handleQuantityChange(item.id, Number(e.target.value))
                            }
                          />
                        </div>
                      </td>
                      <td className="cart__total">
                          ${item && item.price && item.quantity ? (item.price * item.quantity).toFixed(2) : "0.00"}
                      </td>
                      <td className="cart__close">
                        <span
                          className="icon_close"
                          onClick={() => handleRemove(item.sku)}
                        ></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              : <div className="cart__btn">
                  <a href="/shop">Continue Shopping</a>
                </div>}

                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="cart__btn">
                <a href="/shop">Continue Shopping</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="cart__btn update__btn">
                <a href="#">
                  <span className="icon_loading"></span> Update cart
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="discount__content">
                <h6>Discount codes</h6>
                <form action="#">
                  <input type="text" placeholder="Enter your coupon code" />
                  <button type="submit" className="site-btn">
                    Apply
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-2">
              <div className="cart__total__procced">
                <h6>Cart total</h6>
                <ul>
                  <li>
                    {/* Subtotal <span>${subtotal.toFixed(2)}</span> */}
                  </li>
                  <li>
                    {/* Total <span>${subtotal.toFixed(2)}</span> */}
                  </li>
                </ul>
                <a href="/checkout" className="primary-btn">
                  Proceed to checkout
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CartPageWrapper>
  );
};

export default CartPage;
