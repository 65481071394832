import React from 'react';
import PropTypes from 'prop-types';
import { HomePageWrapper } from './HomePage.styled';
import Trend from '../../components/Trend/Trend';
import Banner from '../../components/Banner/Banner';
import Product from '../../components/Product/Product';
import Categories from '../../components/Categories/Categories';
import Discount from '../../components/Discount/Discount';
import Services from '../../components/Services/Services';
import NewProductData from '../../data/new_product_db.json';
import trendsData from '../../data/trend_db.json';
import { data } from 'react-router-dom';

const HomePage = () => {
     return (
          <HomePageWrapper data-testid="HomePage">
               <Categories />
               <Product NewProductData={NewProductData}/>
               <Banner />
               <Trend  trendsData={trendsData} />
               <Discount />
               <Services />
          </HomePageWrapper>
     )};
export default HomePage;
