import styled from 'styled-components';

// If you need to add props, you can type them like this:
// interface FooterWrapperProps {
//   customPadding?: string;
// }

export const FooterWrapper = styled.div`
  // Add your styles here
  // background-color: #333;
  // padding: 20px;
  // color: white;
`;

// If you have props, you can use this version:

// export const FooterWrapper = styled.div<FooterWrapperProps>`
//   background-color: #333;
//   padding: ${(props) => props.customPadding || '20px'};
//   color: white;
// `;
