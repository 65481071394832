import React from 'react';
import { ShopPageWrapper } from './ShopPage.styled';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SidebarCategories from '../../components/SidebarCategories/SidebarCategories';
import FilteredProduct from '../../components/FilteredProduct/FilteredProduct';
import { useParams } from 'react-router-dom';
import products from '../../data/products_db.json'; // Adjust the path based on your project structure
   
const categories = [
    {
      name: 'Wall Art',
      subcategories: ['Canvas Art', 'Framed Prints', 'Posters', 'Wall Stickers', 'Mirrors', 'Clocks'],
    },
    {
      name: 'Lighting',
      subcategories: ['Table Lamps', 'Floor Lamps', 'Chandeliers', 'Pendant Lights', 'Wall Lights', 'LED Strips'],
    },
    {
      name: 'Furniture',
      subcategories: ['Sofas', 'Chairs', 'Coffee Tables', 'Side Tables', 'Bookshelves', 'Cabinets'],
    },
    {
      name: 'Rugs & Carpets',
      subcategories: ['Area Rugs', 'Runner Rugs', 'Shaggy Rugs', 'Outdoor Rugs', 'Matting'],
    },
    {
      name: 'Curtains & Blinds',
      subcategories: ['Window Curtains', 'Blackout Curtains', 'Roller Blinds', 'Venetian Blinds', 'Roman Blinds'],
    },
    {
      name: 'Home Decor Accessories',
      subcategories: ['Vases', 'Cushions', 'Throws & Blankets', 'Picture Frames', 'Decorative Bowls'],
    },
    {
      name: 'Bedding',
      subcategories: ['Bed Linen', 'Duvet Covers', 'Pillows', 'Mattress Protectors', 'Bed Throws'],
    },
    {
      name: 'Outdoor Decor',
      subcategories: ['Garden Furniture', 'Planters', 'Outdoor Lighting', 'Outdoor Rugs', 'Garden Sculptures'],
    },
    {
      name: 'Storage & Organization',
      subcategories: ['Storage Baskets', 'Wall Shelves', 'Storage Cabinets', 'Hooks & Racks', 'Storage Boxes'],
    },
    {
      name: 'Mirrors',
      subcategories: ['Full-Length Mirrors', 'Wall Mirrors', 'Decorative Mirrors', 'Floor Mirrors'],
    },
];

const ShopPage = () => {
// Get the 'category' parameter from the URL
const { category } = useParams();
const filteredProducts = products;

if (category ){
    const filteredProducts = products.filter(product => product.categories.includes(category));
    if (!filteredProducts) {
      return <div>Product not found</div>;
    } 
}

return (
 <ShopPageWrapper data-testid="ShopPage">
        <Breadcrumb />
        <section className="shop spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <div className="shop__sidebar">
                            <div className="sidebar__categories">
                                <SidebarCategories categories={categories} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <FilteredProduct products={filteredProducts} />
                    </div>
                </div>
            </div>
        </section>
 </ShopPageWrapper>
)};

export default ShopPage;
