import React from 'react';
import PropTypes from 'prop-types';
import { AddToCartWrapper } from './AddToCart.styled';
import { addToWishlist, addToCart } from "../../utils/cookieUtils";

const AddToCart = ({ product, onCartUpdate, onWishlistUpdate }) => { 

  const handleAddToCart = () => {
    addToCart(product);
    alert(`${product.product_name} added to cart!`);
    onCartUpdate(); //Update cart count in Header
  };

  const handleAddToWishlist = () => {
    addToWishlist(product);
    alert(`${product.product_name} added to wishlist!`);
    onWishlistUpdate(); //Update wishlist count in Header
  };

  return (
    <AddToCartWrapper data-testid="AddToCart">
      <ul className="product__hover">
        <li>
          <a target="blank" href={`https://www.amazon.com/dp/${product.asin1}?tag=aktel-20`} className="image-popup">
            <i className="fa fab fa-amazon"></i>
          </a>
        </li>
        <li>
          <a href="#" onClick={handleAddToWishlist}>
            <span className="icon_heart_alt"></span>
          </a>
        </li>
        <li>
          <a href="#" onClick={handleAddToCart}>
            <span className="icon_bag_alt"></span>
          </a>
        </li>
      </ul>
    </AddToCartWrapper>
  );
};

AddToCart.propTypes = {   
  product: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    asin1: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
  }).isRequired,
  onCartUpdate: PropTypes.func.isRequired, // Callback for updating cart count
  onWishlistUpdate: PropTypes.func.isRequired, // Callback for updating wishlist count
};

export default AddToCart;
