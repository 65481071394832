import React from 'react';
import PropTypes from 'prop-types';
import { RealatedProductWrapper } from './RealatedProduct.styled';

const RealatedProduct = ({ products }) => {
  
  return (
  <RealatedProductWrapper data-testid="RealatedProduct">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="related__title">
          <h5>RELATED PRODUCTS</h5>
        </div>
      </div>

      {products.map((product, index) => (
        <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
          <div className="product__item">
            <div className="product__item__pic set-bg"    style={{
                  backgroundImage: `url(${
                    product.images && product.images.length > 0
                      ? product.images[0]["Image URL"]
                      : '/img/product/placeholder.jpg'
                  })`,}}>
              {product.isNew && <div className="label new">New</div>}
              {product.isOutOfStock && <div className="label stockout">Out of Stock</div>}
              <ul className="product__hover">
                <li><a href={`/product/${product.sku}`}  className="image-popup"><span className="arrow_expand"></span></a></li>
                <li><a href="#"><span className="icon_heart_alt"></span></a></li>
                <li><a href="#"><span className="icon_bag_alt"></span></a></li>
              </ul>
            </div>
            <div className="product__item__text">
              <h6><a href={`/product/${product.sku}`}>{product.name}</a></h6>
              <div className="rating">
                {[...Array(5)].map((_, i) => (
                  <i key={i} className="fa fa-star"></i>
                ))}
              </div>
              <div className="product__price">${product.price}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </RealatedProductWrapper>
)};

// RealatedProduct.propTypes = {
//   products: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       price: PropTypes.number.isRequired,
//       isNew: PropTypes.bool,
//       isOutOfStock: PropTypes.bool,
//     })
//   ).isRequired,
// };



RealatedProduct.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      asin1: PropTypes.string.isRequired,
      product_name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      listing_id: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      open_date: PropTypes.string.isRequired,
      product_id_type: PropTypes.string.isRequired,
      note: PropTypes.string,
      // condition: PropTypes.string.isRequired,
      will_ship_internationally: PropTypes.string,
      expedited_shipping: PropTypes.string,
      product_id: PropTypes.string.isRequired,
      pending_quantity: PropTypes.number.isRequired,
      fulfillment_channel: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.object).isRequired,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      rating: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      labelClass: PropTypes.string.isRequired,
    })
  ).isRequired};


export default RealatedProduct;
