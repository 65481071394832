import React from 'react';
import PropTypes from 'prop-types';
import { SeachWrapper } from './Seach.styled';

const Seach = () => (
 <SeachWrapper data-testid="Seach">
    <div className="search-model">
    <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="search-close-switch">+</div>
        <form className="search-model-form">
            <input type="text" id="search-input" placeholder="Search here....." />
        </form>
    </div>
</div>
 </SeachWrapper>
);

// Seach.propTypes = {};

// Seach.defaultProps = {};

export default Seach;
