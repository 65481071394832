import React, { useState, useEffect } from 'react';
import { CheckoutPageWrapper } from './CheckoutPage.styled';
import { getCartItems } from '../../utils/cookieUtils';  // Assumed utility for getting cart items from cookies

const CheckoutPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    const items = getCartItems(); // Get cart items from cookies
    setCartItems(items);

    // Calculate subtotal based on cart items
    const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setSubtotal(total);
  }, []);

  // Helper function to format currency
  const formatCurrency = (amount) => `$${amount.toFixed(2)}`;

  return (
    <CheckoutPageWrapper data-testid="CheckoutPage">
      <section className="checkout spad">
        <div className="container">
          
          <form action="/order-complete" method="get" className="checkout__form">
            <div className="row">
              <div className="col-lg-8">
                <h5>Billing detail</h5>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="checkout__form__input">
                            <p>First Name <span>*</span></p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="checkout__form__input">
                            <p>Last Name <span>*</span></p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="checkout__form__input">
                            <p>Address <span>*</span></p>
                            <input type="text" placeholder="Street Address" />
                            <input type="text" placeholder="Apartment. suite, unite ect ( optinal )" />
                        </div>
                        <div className="checkout__form__input">
                            <p>Town/City <span>*</span></p>
                            <input type="text" />
                        </div>
                        <div className="checkout__form__input">
                            <p>Postcode/Zip <span>*</span></p>
                            <input type="text" />
                        </div>
                        <div className="checkout__form__input">
                            <p>Country/State <span>*</span></p>
                            <input type="text" />
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="checkout__form__input">
                            <p>Phone <span>*</span></p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="checkout__form__input">
                            <p>Email <span>*</span></p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="checkout__form__checkbox">
                            <label for="acc">
                                Create an acount?
                                <input type="checkbox" id="acc" />
                                <span className="checkmark"></span>
                            </label>
                            <p>Create am acount by entering the information below. If you are a returing
                                customer login at the <br />top of the page</p>
                            </div>
                            <div className="checkout__form__input">
                                <p>Account Password <span>*</span></p>
                                <input type="text" />
                            </div>
                            <div className="checkout__form__checkbox">
                                <label for="note">
                                    Note about your order, e.g, special note for delivery
                                    <input type="checkbox" id="note" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="checkout__form__input">
                                <p>Oder notes <span>*</span></p>
                                <input type="text" placeholder="Note about your order, e.g, special note for delivery" />
                            </div>
                        </div>
                        </div>
              </div>

              <div className="col-lg-4">
                <div className="checkout__order">
                  <h5>Your order</h5>
                  <div className="checkout__order__product">
                    <ul>
                      <li>
                        <span className="top__text">Product</span>
                        <span className="top__text__right">Total</span>
                      </li>
                      {cartItems.map((item, index) => (
                        <li key={index}>
                          {item.product_name} <span>{formatCurrency(item.price * item.quantity)}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="checkout__order__total">
                    <ul>
                      <li>Subtotal <span>{formatCurrency(subtotal)}</span></li>
                      <li>Total <span>{formatCurrency(subtotal)}</span></li>
                    </ul>
                  </div>
                  <div className="checkout__order__widget">
                    <label htmlFor="o-acc">
                      Create an account?
                      <input type="checkbox" id="o-acc" />
                      <span className="checkmark"></span>
                    </label>
                    <p>Create an account by entering the information below. If you are a returning customer, login at the top of the page.</p>
                    {/* <label htmlFor="check-payment">
                      Cheque payment
                      <input type="checkbox" id="check-payment" />
                      <span className="checkmark"></span>
                    </label> */}
                    {/* <label htmlFor="paypal">
                      PayPal
                      <input type="checkbox" id="paypal" />
                      <span className="checkmark"></span>
                    </label> */}
                  </div>
                  <button type="submit" className="site-btn">Place order</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </CheckoutPageWrapper>
  );
};

export default CheckoutPage;

