import Cookies from "js-cookie";

const CART_COOKIE_NAME = "cart";
const WISHLIST_COOKIE_NAME = "wishlist";

// Get the items from the cart
const getCart = () => JSON.parse(Cookies.get(CART_COOKIE_NAME) || "[]");

// Add an item to the cart
const addToCart = (item) => {
  const cart = getCart();
  const existingItem = cart.find((cartItem) => cartItem.sku === item.sku);

  if (existingItem) {
    existingItem.quantity += item.quantity; // Update quantity if the item already exists
  } else {
    cart.push(item);
  }

  Cookies.set(CART_COOKIE_NAME, JSON.stringify(cart), { expires: 7 }); // Expires in 7 days
};

// Remove an item from the cart
const removeFromCart = (sku) => {
  const cart = getCart();
  const updatedCart = cart.filter((item) => item.sku !== sku);
  Cookies.set(CART_COOKIE_NAME, JSON.stringify(updatedCart), { expires: 7 });
};

// Update the quantity of an item in the cart
const updateCartItemQuantity = (sku, quantity) => {
  const cart = getCart();
  const updatedCart = cart.map((item) =>
    item.sku === sku ? { ...item, quantity } : item
  );

  Cookies.set(CART_COOKIE_NAME, JSON.stringify(updatedCart), { expires: 7 });
};

// Get the items from the wishlist
const getWishlist = () => JSON.parse(Cookies.get(WISHLIST_COOKIE_NAME) || "[]");

// Add an item to the wishlist
const addToWishlist = (item) => {
  const wishlist = getWishlist();
  if (!wishlist.some((wishlistItem) => wishlistItem.sku === item.sku)) {
    wishlist.push(item);
    Cookies.set(WISHLIST_COOKIE_NAME, JSON.stringify(wishlist), { expires: 7 });
  }
};

// Remove an item from the wishlist
const removeFromWishlist = (sku) => {
  const wishlist = getWishlist();
  const updatedWishlist = wishlist.filter((item) => item.sku !== sku);
  Cookies.set(WISHLIST_COOKIE_NAME, JSON.stringify(updatedWishlist), { expires: 7 });
};

// Count the items in the cart
const getCartItemCount = () => {
  const cart = getCart();
  return cart.reduce((total, item) => total + (item.quantity || 1), 0); // Sum up quantities or default to 1
};

// Count the items in the wishlist
const getWishlistItemCount = () => {
  const wishlist = getWishlist();
  return wishlist.length;
};

// Helper function to get a cookie by name (optional, could be merged with js-cookie functionality)
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

// Function to get cart items from cookies (deprecated in favor of using `getCart`)
export const getCartItems = () => getCart();

// Function to add an item to the cart and store it in cookies
export const addToCartCookie = (product) => {
  const cart = getCart();
  const existingProductIndex = cart.findIndex(item => item.product_id === product.product_id);

  if (existingProductIndex >= 0) {
    cart[existingProductIndex].quantity += 1;
  } else {
    cart.push({
      ...product,
      quantity: 1,
    });
  }

  Cookies.set(CART_COOKIE_NAME, JSON.stringify(cart), { expires: 7 });
};

// Function to remove an item from the cart
export const removeFromCartCookie = (productId) => {
  const cart = getCart();
  const updatedCart = cart.filter(item => item.product_id !== productId);
  Cookies.set(CART_COOKIE_NAME, JSON.stringify(updatedCart), { expires: 7 });
};

export {
  getCart,
  removeFromCart,
  addToCart,
  updateCartItemQuantity,
  getWishlist,
  addToWishlist,
  removeFromWishlist,
  getCartItemCount,
  getWishlistItemCount,
  getCookie
};
