import React from 'react';
import PropTypes from 'prop-types';
import { SidebarCategoriesWrapper } from './SidebarCategories.styled';

const SidebarCategories = ({ categories  = [] }) => (
  <SidebarCategoriesWrapper data-testid="SidebarCategories">
    <div className="section-title">
      <h4>Categories</h4>
    </div>
    <div className="categories__accordion">
      <div className="accordion" id="accordionExample">
        {categories.map((category, index) => (
          <div className="card" key={index}>
            <div className={`card-heading ${index === 0 ? 'active' : ''}`}>
              <a
                data-toggle="collapse"
                data-target={`#collapse${index}`}
                aria-expanded={index === 0}
                aria-controls={`collapse${index}`}
              >
                {category.name}
              </a>
            </div>
            <div
              id={`collapse${index}`}
              className={`collapse ${index === 0 ? 'show' : ''}`}
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <ul>
                  {category.subcategories.map((subcategory, subIndex) => (
                    <li key={subIndex}>
                      <a href={`/products/${subcategory}`}>{subcategory}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </SidebarCategoriesWrapper>
);

SidebarCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subcategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

export default SidebarCategories;
