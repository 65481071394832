import React from 'react';
import PropTypes from 'prop-types';
import { TrendWrapper } from './Trend.styled';

const Trend = ({ trendsData }) => (
  <TrendWrapper data-testid="Trend">
    <section className="trend spad">
      <div className="container">
        <div className="row">
          {trendsData.map((section, index) => (
            <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
              <div className="trend__content">
                <div className="section-title">
                  <h4>{section.title}</h4>
                </div>
                {section.items.map((item, itemIndex) => (
                  <div className="trend__item" key={itemIndex}>
                    <div className="trend__item__pic">
                    <img 
                        src={item.images[0]['Image URL']} 
                        alt={item.product_name}  
                        style={{ maxWidth: '100px' }} 
                        />
                    </div>
                    <div className="trend__item__text">
                    <a href={`/product/${item.sku}`}><h6>{item.product_name}</h6></a>
                      <div className="rating">
                        {Array.from({ length: item.rating }).map((_, i) => (
                          <i className="fa fa-star"  key={i}></i>
                        ))}
                      </div>
                      <div className="product__price">${item.price}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </TrendWrapper>
);

Trend.propTypes = {
  trendsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          product_name: PropTypes.string.isRequired,
          images: PropTypes.arrayOf(PropTypes.object),
          price: PropTypes.number.isRequired,
          rating: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default Trend;
