import React, { ReactNode } from 'react';
import './BoxPage.styled'; // If you have any styles for the layout

interface BoxPageProps {
  children: ReactNode;
}

const BoxPage: React.FC<BoxPageProps> = ({ children }) => {
  return (
    <div className="box-page">
      <div className="box-page-content">
        {children}
      </div>
    </div>
  );
};

export default BoxPage;
