import React from 'react';
import PropTypes from 'prop-types';
import { ReviewsWrapper } from './Reviews.styled';

const Reviews = () => (
 <ReviewsWrapper data-testid="Reviews">
    <h6>Reviews ( 2 )</h6>
    <p></p>
 </ReviewsWrapper>
);

export default Reviews;
