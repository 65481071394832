import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BoxPage from './layout/BoxPage/BoxPage'; // Layout
import Header from './components/Header/Header'; // Header component
import Footer from './components/Footer/Footer'; // Footer component
import HomePage from './pages/HomePage/HomePage';
import ShopPage from './pages/ShopPage/ShopPage';
import ContactPage from './pages/ContactPage/ContactPage';
import BlogPage from './pages/BlogPage/BlogPage';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import ProductDetailsPage from './pages/ProductDetailsPage/ProductDetailsPage';
import CartPage from './pages/CartPage/CartPage';
import Instagram from './components/Instagram/Instagram';
import WishlistPage from './pages/WishlistPage/WishlistPage';
import OrderPage from './pages/OrderPage/OrderPage';

const footerData = {
  about: {
    logoLink: "/",
    logoSrc: "/red-logo.png",
    description: "YobUP: Redefining Elegance in Home Décor",
    paymentMethods: [
      { link: "#", imgSrc: "/img/payment/payment-1.png", alt: "Payment 1" },
      { link: "#", imgSrc: "/img/payment/payment-2.png", alt: "Payment 2" },
    ],
  },
  quickLinks: {
    title: "Quick links",
    links: [
      { href: "/about", text: "About" },
      { href: "/blog", text: "Blogs" },
    ],
  },
  account: {
    title: "Account",
    links: [
      { href: "/account", text: "My Account" },
      { href: "/order-track", text: "Orders Tracking" },
    ],
  },
  newsletter: {
    title: "Newsletter",
    formAction: "#",
    placeholder: "Email",
    buttonText: "Subscribe",
    socialLinks: [
      { href: "https://facebook.com/theyobup", iconClass: "fa-facebook" },
      { href: "https://x.com/theyobup", iconClass: "fa-twitter" },
    ],
  },
  copyright: {
    text: "© 2024 All rights reserved.",
    link: { href: "https://www.yobup.com", text: "yobup" },
  },
};

const instagramItems = [
  { imgSrc: '/img/instagram/insta-1.jpg' },
  { imgSrc: '/img/instagram/insta-2.jpg' },
  { imgSrc: '/img/instagram/insta-3.jpg' },
  { imgSrc: '/img/instagram/insta-4.jpg' },
  { imgSrc: '/img/instagram/insta-5.jpg' },
  { imgSrc: '/img/instagram/insta-6.jpg' },
];


const App: React.FC = () => {
  return (
    <Router>
      <BoxPage>
        {/* Render Header and Search globally */}
        <Header />
        {/* <Breadcrumb /> */}
        <div className="content">
          {/* Set up routing for each page */}
          <Routes>
          <Route path="/" element={<HomePage />} />
            <Route path="/products/:category" element={<ShopPage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/product/:sku" element={<ProductDetailsPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/wishlist" element={<WishlistPage />} />
            <Route path="/order-complete" element={<OrderPage />} />
          </Routes>
        </div>

        <Instagram items={instagramItems} username="theyobup" />        
        {/* Render Footer globally */}
        <Footer footerData={footerData} />
      </BoxPage>
    </Router>
  );
};

export default App;
