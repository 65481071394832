import React, {useState , useEffect} from 'react';
import { ProductWrapper } from './Product.styled';
import AddToCart from '../AddToCart/AddToCart';
import { getCartItemCount, getWishlistItemCount } from "../../utils/cookieUtils";

const Product = ({ NewProductData }) => {



  
  // State for cart and wishlist counts
  const [cartCount, setCartCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);

  

  useEffect(() => {
    // Fetch initial cart and wishlist counts
    setCartCount(getCartItemCount());
    setWishlistCount(getWishlistItemCount());
  }, []);


  // Function to update cart count
  const updateCartCount = () => {
    setCartCount(getCartItemCount());
  };

  // Function to update wishlist count
  const updateWishlistCount = () => {
    setWishlistCount(getWishlistItemCount());
  };

  return (
    <ProductWrapper data-testid="Product">
      <section className="product spad">
        <div className="container">
          {/* Section Title */}
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="section-title">
                <h4>New Products</h4>
              </div>
            </div>
            <div className="col-lg-8 col-md-8">
              <ul className="filter__controls">
                {[
                  { label: 'All', filter: '*' },
                  { label: 'Wall Arts', filter: 'wall_arts' },
                  { label: 'Latest Designs', filter: 'latest_desines' },
                  { label: 'Big Size Paintings', filter: 'big_size_painting' },
                  { label: 'Vaastu Wall Paintings', filter: 'vastu_wall_painting' },
                  { label: 'Figurine', filter: 'figurine' },
                ].map(({ label, filter }) => (
                  <li key={filter} className={filter === '*' ? 'active' : ''} data-filter={`.${filter}`}>
                    {label}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Products */}
          <div className="row property__gallery">
            {NewProductData.map((product, index) => {
              const {
                sku,
                product_name,
                rating,
                price,
                originalPrice,
                label,
                images,
                categories,
              } = product;

              const imageUrl =
                images && images.length > 0
                  ? images[0]['Image URL']
                  : '/img/product/placeholder.jpg';

              return (
                <div
                  key={`${sku}-${index}`} // Ensures unique key
                  className={`col-lg-3 col-md-4 col-sm-6 mix ${categories.join(' ')}`}
                >
                  <div className="product__item">
                    <div
                      className="product__item__pic set-bg"
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    >
                      {label && (
                        <div className={`label ${label.toLowerCase()}`}>{label}</div>
                      )}

                      <AddToCart 
                        product={{ product_name: product.product_name, price: product.price, sku: product.sku, asin1:product.asin1, quantity: 1, image:   product.images && product.images.length > 0 ? product.images[0]["Image URL"]  : '/img/product/placeholder.jpg' , rating: product.rating }}
                        onCartUpdate={updateCartCount} // Pass the callback to update cart count
                        onWishlistUpdate={updateWishlistCount} // Pass the callback to update wishlist count
                      />
                      
                    </div>
                    <div className="product__item__text">
                      <h6>
                        <a href={`/product/${sku}`}>{product_name}</a>
                      </h6>
                      <div className="rating">
                        {Array.from({ length: rating }).map((_, index) => (
                          <i key={index} className="fa fa-star"></i>
                        ))}
                      </div>
                      <div className="product__price">
                        ${price}
                        {originalPrice && <span>${originalPrice}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </ProductWrapper>
  );
};

export default Product;
