import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductDetailsPageWrapper } from './ProductDetailsPage.styled';
import RealatedProduct from '../../components/RealatedProduct/RealatedProduct';
import ProductDetailsPic from '../../components/ProductDetailsPic/ProductDetailsPic';
import products from '../../data/products_db.json'; // Adjust the path based on your project structure
import Reviews from '../../components/Reviews/Reviews';
import Specification from '../../components/Specification/Specification';
import { addToWishlist } from "../../utils/cookieUtils";
import { addToCart } from "../../utils/cookieUtils";

const ProductDetailsPage = () => {
  // Get the 'sku' parameter from the URL
  const { sku } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);

  // Get product details based on SKU
  useEffect(() => {
    const foundProduct = products.find((product) => product.sku === sku);
    if (foundProduct) {
      setProduct(foundProduct);
    }
  }, [sku]); // Run only when SKU changes

  // Get related products based on category
  useEffect(() => {
    if (product) {
      const related = products
        .filter((p) => p.categories.some((category) => product.categories.includes(category)))
        .slice(0, 4); // Get only 4 related products
      setRelatedProducts(related);
    }
  }, [product]); // Only run when `product` changes

  if (!product) {
    return <div>Product not found</div>;
  }


  const handleAddToCart = () => {
    addToCart(product);
    alert(`${product.product_name} added to cart!`);
  };

  const handleAddToWishlist = () => {
    addToWishlist(product);
    alert(`${product.product_name} added to wishlist!`);
  };
  


  const handleIncrease = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleDecrease = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1)); // Prevent negative values
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1); // Default to 1 if invalid input
    }
  };



  return (
    <ProductDetailsPageWrapper data-testid="ProductDetailsPage">
      <section className="product-details spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ProductDetailsPic images={product.images} />
            </div>
            <div className="col-lg-6">
              <div className="product__details__text">
                <h3>
                  {product.product_name} <span>Brand: yobup</span>
                </h3>
                <div className="rating">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <span>( reviews )</span>
                </div>
                <div className="product__details__price">
                  ${product.price}
                  <span>$ 83.0</span>
                </div>
                <p>{product.description}</p>
                <div className="product__details__button">
                  <div className="quantity">
                    <span>Quantity:</span>
                    <div className="pro-qty">
                      <button type="button" onClick={handleDecrease}>  - </button>
                      <input
                        type="text"
                        value={quantity}
                        onChange={handleInputChange}
                      />
                      <button type="button" onClick={handleIncrease}>
                        +
                      </button>
                    </div>
                  </div>
                  <a href="#" className="cart-btn" onClick={handleAddToCart}>
                    <span className="icon_bag_alt"></span> Add to cart
                  </a>
                  <ul>
                    <li>
                      <a href="#" onClick={handleAddToWishlist}>
                        <span className="icon_heart_alt"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon_adjust-horiz"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="product__details__tab">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">
                      Specification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab">
                      Reviews
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <h6>Description</h6>
                    <p>{product.description}</p>
                  </div>
                  <div className="tab-pane" id="tabs-2" role="tabpanel">
                    <Specification />
                  </div>
                  <div className="tab-pane" id="tabs-3" role="tabpanel">
                    <Reviews sku={product.sku} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RealatedProduct products={relatedProducts} />
        </div>
      </section>
    </ProductDetailsPageWrapper>
  );
};

export default ProductDetailsPage;
