// import React, { useState, useEffect } from 'react';
// import { HeaderWrapper } from './Header.styled';
// import Seach from '../Seach/Seach'; // Search component
// import {getCartItemCount, getWishlistItemCount } from "../../utils/cookieUtils";



import React, { useState, useEffect } from 'react';
import { HeaderWrapper } from './Header.styled';
import Seach from '../Seach/Seach'; // Search component
import { getCartItemCount, getWishlistItemCount } from "../../utils/cookieUtils";
import AddToCart from '../AddToCart/AddToCart'; // AddToCart component import


  
const categories = [
  {
    name: 'Wall Art',
    subcategories: ['Canvas Art', 'Framed Prints', 'Posters', 'Wall Stickers', 'Mirrors', 'Clocks'],
  },
  {
    name: 'Lighting',
    subcategories: ['Table Lamps', 'Floor Lamps', 'Chandeliers', 'Pendant Lights', 'Wall Lights', 'LED Strips'],
  },
  {
    name: 'Furniture',
    subcategories: ['Sofas', 'Chairs', 'Coffee Tables', 'Side Tables', 'Bookshelves', 'Cabinets'],
  },
  {
    name: 'Rugs & Carpets',
    subcategories: ['Area Rugs', 'Runner Rugs', 'Shaggy Rugs', 'Outdoor Rugs', 'Matting'],
  },
  {
    name: 'Curtains & Blinds',
    subcategories: ['Window Curtains', 'Blackout Curtains', 'Roller Blinds', 'Venetian Blinds', 'Roman Blinds'],
  },
  {
    name: 'Home Decor Accessories',
    subcategories: ['Vases', 'Cushions', 'Throws & Blankets', 'Picture Frames', 'Decorative Bowls'],
  },
  {
    name: 'Bedding',
    subcategories: ['Bed Linen', 'Duvet Covers', 'Pillows', 'Mattress Protectors', 'Bed Throws'],
  },
  {
    name: 'Outdoor Decor',
    subcategories: ['Garden Furniture', 'Planters', 'Outdoor Lighting', 'Outdoor Rugs', 'Garden Sculptures'],
  },
  {
    name: 'Storage & Organization',
    subcategories: ['Storage Baskets', 'Wall Shelves', 'Storage Cabinets', 'Hooks & Racks', 'Storage Boxes'],
  },
  {
    name: 'Mirrors',
    subcategories: ['Full-Length Mirrors', 'Wall Mirrors', 'Decorative Mirrors', 'Floor Mirrors'],
  },
];


//   // Define menu items dynamically
  const menuItems = [
    { name: 'Wall Arts', key: 'Wall Arts' },
    { name: 'Latest Designs', key: 'Latest Designs' },
    { name: 'Big Size Paintings', key: 'Big Size Paintings' },
    { name: 'Vaastu Wall Paintings', key: 'Vaastu Wall Paintings' },
    { name: 'Figurine', key: 'Figurine' },
  ];





const Header = () => { 


  // State for cart and wishlist counts
  const [cartCount, setCartCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);

  useEffect(() => {
    // Fetch initial cart and wishlist counts
    setCartCount(getCartItemCount());
    setWishlistCount(getWishlistItemCount());
  }, []);

  // Function to update cart count
  const updateCartCount = () => {
    setCartCount(getCartItemCount());
  };

  // Function to update wishlist count
  const updateWishlistCount = () => {
    setWishlistCount(getWishlistItemCount());
  };

  return (
    <HeaderWrapper data-testid="Header">
      <Seach />
      <header className="header">
        <div className="container-fluid">
          <div className="row">
            {/* Logo */}
            <div className="col-xl-2 col-lg-2">
              <div className="header__logo">
                <a href="/"><img src="/logo.png" alt="Logo"  className='logo' /></a>
              </div>
            </div>

            {/* Navigation */}
            <div className="col-xl-7 col-lg-7">
              <nav className="header__menu">
                <ul>
                  {/* Static Links */}
                  <li><a href="/">Home</a></li>
                  {/* Dynamic Menu Items */}
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <a href={`/products/${item.key.toLowerCase()}`}>{item.name}</a>
                    </li>
                  ))}
                  {/* Categories Dropdown */}
                  <li className="dropdown">
                    <a href="#" className="dropdown-toggle">More</a>
                    <ul className="dropdown">
                      {categories.map((category, index) => (
                        <li key={index} className="dropdown-submenu">
                          <a href={`/products/${category.name.toLowerCase()}`}>{category.name}</a>
                          <ul className="dropdown-menu">
                            {category.subcategories.map((subcategory, idx) => (
                              <li key={idx}>
                                <a href={`/products/${subcategory.toLowerCase()}`}>{subcategory}</a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>

            {/* Right Section */}
            <div className="col-lg-3">
              <div className="header__right">
                {/* Auth Links */}
                <div className="header__right__auth">
                  <a href="/login">Login</a>
                  <a href="/register">Register</a>
                </div>
                {/* Icons */}
                <ul className="header__right__widget">
                  <li><span className="icon_search search-switch"></span></li>
                  <li>
                    <a href="/wishlist">
                      <span className="icon_heart_alt"></span>
                      <div className="tip">{wishlistCount}</div>
                    </a>
                  </li>
                  <li>
                    <a href="/cart">
                      <span className="icon_bag_alt"></span>
                      <div className="tip">{cartCount}</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Example AddToCart usage with product */}
      {/* <AddToCart 
        product={{ product_name: 'Sample Product', price: 100, sku: '1234', asin1: 'B000123456', quantity: 1, image: '/image.jpg', rating: 4 }}
        onCartUpdate={updateCartCount} // Pass the callback to update cart count
        onWishlistUpdate={updateWishlistCount} // Pass the callback to update wishlist count
      /> */}
    </HeaderWrapper>
  );
};

export default Header;
