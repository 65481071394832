import React from 'react';
import { CategoriesWrapper } from './Categories.styled';

const categoriesData_L = [
  {
    id: 1,
    title: "Bronze figurine",
    description:
      "Discover a stunning collection of handcrafted bronze figurines, perfect for adding elegance and sophistication to any space.",
    image: "/img/categories/category-1.jpg",
    link: "#",
    large: true,
  },
];

const categoriesData = [
  {
    id: 2,
    title: "Wall Art",
    description: "358 items",
    image: "/img/categories/category-2.jpg",
    link: "products/Wall Art",
    large: false,
  },
  {
    id: 3,
    title: "Leather Diary",
    description: "273 items",
    image: "/img/categories/category-3.jpg",
    link: "/products/Leather Diary",
    large: false,
  },
  {
    id: 4,
    title: "Marble Statues",
    description: "159 items",
    image: "/img/categories/category-4.jpg",
    link: "/products/Marble Statues",
    large: false,
  },
  {
    id: 5,
    title: "Table Decor",
    description: "792 items",
    image: "/img/categories/category-5.jpg",
    link: "/products/Table Decor",
    large: false,
  },
];

const Categories = () => {
  return (
    <CategoriesWrapper data-testid="Categories">
      <section className="categories">
        <div className="container-fluid">
          <div className="row">
            {/* Large Category */}
            {categoriesData_L.map((category) => (
              <div className="col-lg-6 p-0" key={category.id}>
                <div
                  className="categories__item categories__large__item set-bg"
                  style={{ backgroundImage: `url(${category.image})` }}
                >
                  <div className="categories__text">
                    <h1>{category.title}</h1>
                    <p>{category.description}</p>
                    <a href={category.link}>Shop now</a>
                  </div>
                </div>
              </div>
            ))}

            {/* Small Categories */}
            <div className="col-lg-6">
              <div className="row">
                {categoriesData.map((category) => (
                  <div className="col-lg-6 col-md-6 col-sm-6 p-0" key={category.id}>
                    <div
                      className="categories__item set-bg"
                      style={{ backgroundImage: `url(${category.image})` }}
                    >
                      <div className="categories__text">
                        <h4>{category.title}</h4>
                        <p>{category.description}</p>
                        <a href={category.link}>Shop now</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CategoriesWrapper>
  );
};

export default Categories;