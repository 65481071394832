import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { FilteredProductWrapper } from './FilteredProduct.styled';
import AddToCart from '../AddToCart/AddToCart';
import { getCartItemCount, getWishlistItemCount } from "../../utils/cookieUtils";


const FilteredProduct = ({ products=[]}) => {

  const productsPerPage = 21; // Define how many products to display per page
  const totalPages = Math.ceil(products.length / productsPerPage);
  const pagination = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [currentPage, setCurrentPage] = useState(1);
  
  // State for cart and wishlist counts
  const [cartCount, setCartCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);

  const currentProducts = products.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
 

  

  useEffect(() => {
    // Fetch initial cart and wishlist counts
    setCartCount(getCartItemCount());
    setWishlistCount(getWishlistItemCount());
  }, []);


  // Function to update cart count
  const updateCartCount = () => {
    setCartCount(getCartItemCount());
  };

  // Function to update wishlist count
  const updateWishlistCount = () => {
    setWishlistCount(getWishlistItemCount());
  };


   return (
  <FilteredProductWrapper data-testid="FilteredProduct">
    <div className="row">
      {currentProducts.map((product, index) => (
        <div className="col-lg-4 col-md-6" key={index}>
          <div className={`product__item ${product.label ? product.label.toLowerCase() : ''}`}>
            <div
              className="product__item__pic set-bg"
              style={{
                  backgroundImage: `url(${
                    product.images && product.images.length > 0
                      ? product.images[0]["Image URL"]
                      : '/img/product/placeholder.jpg'
                  })`,}}>
              {product.label && <div className={`label ${product.labelClass}`}>{product.label}</div>}


              
              <AddToCart 
                product={{ product_name: product.product_name, price: product.price, sku: product.sku, asin1:product.asin1, quantity: 1, image:   product.images && product.images.length > 0 ? product.images[0]["Image URL"]  : '/img/product/placeholder.jpg' , rating: product.rating }}
                onCartUpdate={updateCartCount} // Pass the callback to update cart count
                onWishlistUpdate={updateWishlistCount} // Pass the callback to update wishlist count
              />

              
            </div>
            <div className="product__item__text">
              <h6>
                <a href={`/product/${product.sku}`}>{product.product_name}</a>
              </h6>
              <div className="rating">
                {Array.from({ length: product.rating }).map((_, i) => (
                  <i className="fa fa-star" key={i}></i>
                ))}
              </div>
              <div className="product__price">
                {product.salePrice ? (
                  <>
                    ${product.salePrice} <span>${product.price}</span>
                  </>
                ) : (
                  `$${product.price}`
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    {pagination && (
      <div className="col-lg-12 text-center">
        <div className="pagination__option">
            {pagination.map((page) => (
               <a href="#"  key={page} onClick={() => handlePageChange(page)} >
                  {page}
               </a>

            ))}
        </div>
      </div>
    )}
  </FilteredProductWrapper>
)};



FilteredProduct.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      asin1: PropTypes.string.isRequired,
      product_name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      listing_id: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      open_date: PropTypes.string.isRequired,
      product_id_type: PropTypes.string.isRequired,
      note: PropTypes.string,
      // condition: PropTypes.string.isRequired,
      will_ship_internationally: PropTypes.string,
      expedited_shipping: PropTypes.string,
      product_id: PropTypes.string.isRequired,
      pending_quantity: PropTypes.number.isRequired,
      fulfillment_channel: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.object).isRequired,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      rating: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      labelClass: PropTypes.string.isRequired,
    })
  ).isRequired,
  pagination: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};



export default FilteredProduct;
