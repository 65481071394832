import styled from 'styled-components';

export const HeaderWrapper = styled.div`
 
.logo {
    position: absolute;
    margin-top: -40px;
    width: 190px;
}

ul.dropdown {    width: 200px !important; }

`;
